.section_padding{
    padding: 4rem 4rem;
}

.footer{
    background-color: #2d2d32;
}

.sb_footer{
    display: flex;
    flex-direction: column;
}

.sb_footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    font-family: "roboto";

    margin-bottom: 2rem;

}

.sb_footer-links_div{
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;
}

a{
    color: rgb(175,175, 179);
    text-decoration: none;

}

.socialmedia{
    display: flex;
    flex-direction: row;
}

.socialmedia img{
    width: 50%;
}

.sb_footer-links_div h4{
    font-size: 14px;
    line-height: 17px;
    font-family: "roboto";

    margin-bottom: 0.9rem;
}

.sb_footer-links_div p{
    font-size: 12px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;
}

.sb_footer-below-links{
    display: flex;
    flex-direction: row;
}

.sb_footer-below-links p{
    font-size: 13px;
    line-height: 15px;
    font-family: "roboto";

    margin-left: 2rem;
    
    color: white;
    font-weight: 600;
}

hr{
    color: white !important;
    width: 100%;
}

.sb_footer_copyright p{
    font-size: 13px;
    line-height: 15px;
    color: white;
    font-family: "roboto";

    font-weight: 600;
}

@media screen and (max-width: 850px) {
    .sb_footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
        font-family: "roboto";
    }

    .sb_footer-links {
        justify-content: flex-start;
    }

    .sb_footer-links_div {
        width: 100%;
        margin: 1rem 0;
    }

    .sb_footer-links_div h4 {
        font-size: 16px; /* Increase font size for better readability */
        line-height: 20px; /* Adjust line height */
        margin-bottom: 0.9rem;
    }

    .sb_footer-links_div p {
        font-size: 14px; /* Increase font size for better readability */
        line-height: 18px; /* Adjust line height */
        margin: 0.5rem 0;
    }

    .sb_footer-below-links {
        flex-direction: column;
        align-items: flex-start;
    }

    .sb_footer-below-links p {
        margin-left: 0;
        margin-top: 1rem;
    }

    .sb_footer-btn p {
        font-size: 14px;
        line-height: 20px;
        font-family: "roboto";
    }

}

@media screen and (max-width: 550px) {
    .sb_footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .sb_footer-links_div {
        width: 100%;
        margin: 1rem 0;
    }

    .sb_footer-links_div h4 {
        font-size: 14px; /* Adjust font size */
        line-height: 18px; /* Adjust line height */
        margin-bottom: 0.9rem;
    }

    .sb_footer-links_div p {
        font-size: 12px; /* Adjust font size */
        line-height: 16px; /* Adjust line height */
    }

    .sb_footer-btn p {
        font-size: 12px; /* Adjust font size */
        line-height: 18px; /* Adjust line height */
    }

    .sb_footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}