@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* 
*{
  margin : 0;
  padding : 0;
  font-family : 'Poppins', sans-serif;
}

body{
  display: flex;
  align-items: center;
  justify-content: center;
  height:100vh;
  background:#F7F7F7 ; */


.footer-body {
  height: 700px;
  background-color: #000000;

}



/* form */

/* form */
.form-container {
    width: 100%; /* Adjust to full width */
    max-width: 1000px; /* Limit maximum width */
    margin: 100px auto 20px; /* Center the form horizontally */
    padding: 20px; /* Add padding for spacing */
  }
  
  .title, .slogan {
    text-align: center;
    font-weight: bold; /* Corrected syntax for font-weight */
  }
  
  .btn {
    width: 100%;
  }
  
  .auth-image {
    width: 100%;
    border-radius: 8px;
  }
  
  .alert {
    margin-bottom: 1.5rem;
  }
  
  /* Media queries for responsiveness */
  @media screen and (max-width: 1190px) {
    .form-container {
      max-width: 90%;
    }
  }
  
  @media screen and (max-width: 1100px) {
    .form-container {
      max-width: 85%;
    }
  }
  
  @media screen and (max-width: 970px) {
    .form-container {
      max-width: 80%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .form-container {
      max-width: 90%;
      margin-top: 50px; /* Adjust margin-top for smaller screens */
    }
  }
  
  @media screen and (max-width: 620px) {
    .form-container {
      max-width: 85%;
      margin-top: 100px;

    }
    .auth-image{
        width: 120%;
    }
    
    .title, .slogan {
        text-align: center;
        font-weight: bold;
        /* Add any additional styling needed */
      }
    
  }
  
  @media screen and (max-width: 360px) {
    .form-container {
      max-width: 80%;
      margin-top: 50px;
    }
  }
  
/* Enhanced styling for the WalletPage */
.profile-card {
    max-width: 500px;
    margin: 100px auto 20px;
    padding: 20px;
    background: #f5f5f5; /* Light grey background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .avatar {
    margin-bottom: 1.5rem;
    background-color: #007bff; /* Blue background for avatar */
    color: #fff; /* White icon color */
    border-radius: 50%; /* Circle shape */
  }
  
  .username {
    text-transform: capitalize;
    color: #333; /* Darker text color */
    margin-bottom: 0.5rem; /* Space below the title */
  }
  
  .profile-btn {
    margin-top: 1.3rem;
    width: 100%;
    background: linear-gradient(90deg, #007bff, #00d2ff); /* Gradient background */
    border: none; /* Remove default border */
    color: #fff; /* White text color */
    font-size: 16px; /* Increase font size */
    border-radius: 5px; /* Rounded corners for button */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Button shadow */
    transition: background 0.3s, box-shadow 0.3s; /* Smooth transition */
  }
  
  .profile-btn:hover {
    background: linear-gradient(90deg, #0056b3, #0099ff); /* Darker gradient on hover */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Darker shadow on hover */
  }
  
  /* Responsive design adjustments */
  @media screen and (max-width: 1190px) {
    .profile-card {
      max-width: 90%;
    }
  }
  
  @media screen and (max-width: 1100px) {
    .profile-card {
      max-width: 85%;
    }
  }
  
  @media screen and (max-width: 970px) {
    .profile-card {
      max-width: 80%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .profile-card {
      max-width: 90%;
      margin-top: 50px;
    }
  }
  
  @media screen and (max-width: 620px) {
    .profile-card {
      max-width: 85%;
      margin-top: 100px;
    }
  }
  
  @media screen and (max-width: 360px) {
    .profile-card {
      max-width: 80%;
      margin-top: 50px;
    }
  }
  
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

body{
    margin:0px;
    overflow-x: hidden;
    padding: 0px;
    background-color: #F7F7F7;
    font-family: 'Lato', sans-serif;
    width: 100vw;
}
*{
    box-sizing: border-box;
}
ul{
    list-style: none;
    
}
a{
    text-decoration: none;
}
button{
    outline: none;
    border: none;
}
input{
    outline: none;
    border: none;
}


.logo img{
    
    height: 50px;
    width: 50px;
   

}
.logo {
    font-family: 'Lato';
	height:40px;
	line-height: 43px;
	margin: 3px;
    margin-left: 10px;
	padding: 0px 22px;
	display: flex;
	font-size: 1rem;
	text-transform: uppercase;

	font-weight: 500;
	color:white;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;
    text-decoration: none;
}
#main .header-heading {
    color: white;

}
/*
#main{
    text-align: center;
    border: 1px solid black;
    width:100%;
    height: 900px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('./images/LoanBanner\ \(2\).jpg');
    position: relative;
    z-index: 0;
}


#main::before{
    position: absolute;
    content: "";
    background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61b50808', endColorstr='#99000000',GradientType=1 );
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}*/

#main {
    text-align: center;
    
    width: 100%;
    height: 600px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    /* Set background attachment to fixed */
    background-image: url('./images/LoanBanner (2).jpg');
    position: relative;
    z-index: 0;
}

#main::before {
    position: absolute;
    content: "";
    background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#61b50808', endColorstr='#99000000', GradientType=1);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
nav{
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	width:100%;
	z-index: 1;
    background-color: rgb(5, 33, 92);
    transition: 0.6s ease-in-out;
    backdrop-filter: blur(20px);
}
nav.active{
	box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.157);
    background-color: rgba(5, 33, 92, 0.9);
}



nav ul{
	display: flex;
    color: white;
    
    margin-top: 20px;
}
.active{
    background-color: #FF1414;
    color:#000000;
	box-shadow: 5px 10px 30px rgba(198, 64, 64, 0.411);
	transition: all ease 0.2s;
    
}
.active:hover{
    background-color:rgb(2, 36, 110);
    color:#ffffff;
    
	box-shadow: 5px 10px 30px rgba(33, 189, 189, 0.856);
	transition: all ease 0.2s;
}
nav ul li a{
    font-family: 'Lato';
	height:40px;
	line-height: 43px;
	margin: 3px;
	padding: 0px 22px;
	display: flex;
	font-size: 0.9rem;
	text-transform: uppercase;
	font-weight: 500;
	color:white;
    text-decoration: none;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;
}
nav ul li a:hover{
	background-color: #14ffffb0;
    color:#ffffff;
	box-shadow: 5px 10px 30px #107cbfb0;
	transition: all ease 0.2s;
    cursor:pointer;
    
}
nav .menu-btn,
.menu-icon{
		display:none;
      
        
	}
.name{
    text-align: center;
	font-family: 'Montserrat';
	width: 900px;
	position: absolute;
    height: 100px;

    left: 50%;
	top: 45%;
	transform: translate(-50%,-55%);
}
.name span{
    color: white;
}
.name .details{
    font-family: "roboto";
    font-size: 23px;
    color: #c5c5c5;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 110%;
    max-width: 600px;
    margin: 0 auto;
    height: 100px;

    border-radius: 2px;
    padding: 1.4rem 2rem 1.6rem;
    background: rgba(24, 25, 53, 0.8);}




.name h2{
    font-family: "Lemon", serif;
    font-weight: 400;
    font-style: normal;
    
    font-size: 3.7rem;
	margin:0px;
	letter-spacing: 2px;
	color:#ffffff;
 
}
.name h1{
    font-family: "Lemon", serif;
    font-weight: 400;
    font-style: normal;
	font-size: 4.5rem;
	margin:0px;
	letter-spacing: 2px;
	color:#ffffff;
}
.header-btns{
    display: flex;
    margin-top: 40px;
    margin-left: 40%;
}
.header-btn{
	width:160px;
	height: 50px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background-color: #14dcff;
    box-shadow: 5px 10px 30px rgba(36, 2, 255, 0.336);
    border-radius: 5px;
	color:#100d0d;
}
.header-btn:hover{
	background-color: transparent;
    transition: all ease 0.5s;
    color: #ffffff;
    border: 2px solid #14dcff;
}


/*
#products{
        width: 100%;
        height: 100vh;
        box-sizing: border-box;
        font-family: 'Lato';
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
        background-color: black;
} 

Chatgpt code below */ 

#products {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    font-family: 'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    background-color: black;
    background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        /* Set background attachment to fixed */
        background-image: url('./images/LoanBanner (2).jpg');
        position: relative;
        z-index: 0;
    }
    
    #products::before {
        position: absolute;
        content: "";
        background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
        background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
        background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#61b50808', endColorstr='#99000000', GradientType=1);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }




#products h1{
    color: #f9f9f9;
        font-size: 3rem;
        
}
#features{
    width:100%;
    height:265vh;
    box-sizing: border-box;
    font-family:'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: center;
    padding-top: 40px ;
    background-color: #000000;
}
#features h1{
    color: #f9f9f9;
    font-size: 3rem;
    margin-top: 200px;
}

#features1 {
    width: 100%;
        box-sizing: border-box;
        font-family: 'Lato';
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
        background-color: #F7F7F7;
        font-family: "roboto";

}
#features1 h1 {
    color: #000000;
    font-family: "roboto";

    font-size:40px;
    margin-top: 100px;
}
/*
.a-container{
    
    display: flex;
    margin: 0;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    width: 1000px;


}

Chatgpt code below */

.a-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    max-width: 100%;
}

/*
.a-box{
    background-color: #000000;
    width:250px;
    height: 400px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
}
Chatgpt -> 
*/

.a-box {
    background-color: #ffffff;
    border: 2px solid #14dcff;

    width: 90%;
    /* Adjusted width for better responsiveness */
    max-width: 300px;
    /* Set a maximum width for each box */
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
}

@media (max-width: 360px) {
    .a-box {
        width: 100%;
        max-width: none;
    }
}

/*
.a-b-img{
    width: 100%;
    height: 60%;
    margin: auto;

}


.a-b-img img{
    padding: 10px;
    margin-top: 40px;
    margin-left:65px;
    width:50%;
    height:50%;

}
.a-box:hover{
    border: 1px solid #14dcff;
    box-shadow: 10px 8px 18px rgba(3, 252, 235, 0.445);
    cursor: pointer;
}
.a-b-text{
    width: 100%;
    height: 95%;
    background-color:#000000;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.a-b-text h2{
    color: rgb(252, 252, 252);
}
.a-b-text p{
    margin: 0px;
    color:rgb(252, 252, 252);
    font-size: 0.9rem;
    font-family:'Lato';
    font-weight: bolder;
    
    max-width: 80%;
    margin-top: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  
    text-overflow: ellipsis;
    margin-bottom: 10px;
}
.a-b-text p:hover{
    color:#96929a;
}
.a-b-text a{
    margin-top:15px ;
}
*/
#offer{
    padding-top: 50px;
    width:100%;
    height:768px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    text-align: center;
    background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        /* Set background attachment to fixed */
        background-image: url('./images/LoanBanner (2).jpg');
        position: relative;
        z-index: 0;
    }
    
    #offer::before {
        position: absolute;
        content: "";
        background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
        background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
        background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#61b50808', endColorstr='#99000000', GradientType=1);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }


.pr-heading{
    text-align: center;
	font-family:'Lato';
	width: 600px;
	position: absolute;
    left: 50%;
	top: 55%;
	transform: translate(-50%,-55%);
}
.pr-heading span{
    color: #14e7ff;
}
.pr-heading .details{
    font-size: 23px;
    color: #c5c5c5;
}
.pr-heading .details{
    font-size: 1.2rem;
}
.pr-heading h1{
	font-family:'roboto';
    font-weight: 400;
    font-style: normal;
	font-size: 4rem;
	margin:0px;
	letter-spacing: 3px;
	color:white;
}
.pr-btns{
    display: flex;
    margin-top: 40px;
    margin-left: 35%;
}
.pr-btn{
	width:160px;
	height: 50px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background-color: #ff14f3;
    box-shadow: 5px 10px 30px rgba(251, 2, 255, 0.336);
    border-radius: 5px;
	color:#ffffff;
}
.pr-btn:hover{
	background-color: transparent;
    transition: all ease 0.5s;
    color: #ffffff;
    border: 2px solid #2f14ff;
}

#about{
    margin-top:100px ;
	width:100%;
	height: 120vh;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 50px 5% 0px 5%;
	position: relative;
}

.about-text{
    width: 45%;
    color: #fff;
    list-style: circle;
}
.about-text h1{
	font-size: 3rem;
	color:#FF1414;
    font-weight: 500;
    margin: 0px;
	padding: 0px;
}
.about-text p{
    width: 80%;
	font-size: 1.2rem;
	color:#cecece;
	
}
.about-text button{
    margin-top: 20px;
	width: 140px;
	height: 45px;
	border-radius: 10px;
	border: none;
    outline: none;
    color: #ffffff;
	background-color:#FF1414;
}
.about-text button:hover{
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
	background-color: rgb(0, 0, 0);
    transition: all ease 0.3s;
    color: #ffffff;
}
.about-image{
    width: 50%;
}
.about-image img{
  width: 600px;
}

#contact{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: black;
    background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        /* Set background attachment to fixed */
        background-image: url('./images/LoanBanner (2).jpg');
        position: relative;
        z-index: 0;
    }
    
    #contact::before {
        position: absolute;
        content: "";
        background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
        background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
        background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#61b50808', endColorstr='#99000000', GradientType=1);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }


#contact h1{
    color: #fff;
    font-family: "roboto";
    font-size: 3rem;
}
#contact form{
    width:600px;
    display:flex;
    font-family: "roboto";

    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
#contact form input,
#contact form textarea{
    width:100%;
    height:50px;
    margin:5px 0px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: #ffffff2d;
    color: #ffffff;
    border-radius: 5px;
}
#contact form textarea{
    height: 150px;
}
#contact form input[type="submit"]{
    height: 45px;
    font-style: 'Lato';
    background: linear-gradient(90deg, #065180 20%,#14dcff);
    color: #ffffff;
    text-transform: uppercase;
    cursor: pointer;
}

#pricing{
    width:100%;
    height:125vh;
    box-sizing: border-box;
    font-family:'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px ;
    background-color: #000000;
}
#pricing h1{
    color: #f9f9f9;
    font-size: 3rem;
}

.p-container{
    width: 100%;
    min-height: 100vh;
    background: rgb(0, 0, 0);
    
}

.p-container h2{
    color: #fff ;
    font-size: 32px;
    padding: 50px 0;  
    text-align: center;
    
}

.price-row{
    width: 90%;
    max-width: 1100px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
    grid-gap:25px;

}

.price-col{
    background: #280028;
    padding: 10% 15%;
    border-radius: 10px;
    color:#fff;
    text-align: center;
}

.price-col p{
    font-size: 22px;

}

.price-col h3{
    font-size: 44px;
    margin: 20px 0 40px;
    font-weight: 500; 
}

.price-col h3 span{
    font-size: 16px;

}
.price-col ul{
    text-align: left;
    margin: 20px 0;
    color:#ddd;
    list-style: none;
}

.price-col ul li{
    margin: 15px 0;
}
.price-col ul li::before{
    content:'\2022';
    color: #e33058;
    font-weight: bold;
    margin-right: 8px;
}

.price-col button{
    width: 100%;
    padding: 14px 0;
    background: transparent;
   
    color: #fff;
    font-size: 15px;
    border: 1px solid rgb(255, 0, 242);
    border-radius:6px;
    margin-top: 30px;
    cursor: pointer;
    transition: background 0.5s;

}
.price-col button:hover{
        background: rgb(251, 0, 255);
        background: linear-gradient(90deg, #980b8d 20%,#9400f7);

}

.seperatepage{
        width: 100%;
        font-size: 100px;
        color: white;
}



@media(max-width:620px){
    #contact form{
        width:90%;
    }
}

@media(max-width:1190px){
	#main{
		background-size: 1150px !important;
		
	}
	.name{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .name h2{
        font-size: 1.5rem;
    }
    .name h1{
        font-size: 3rem;
    }
    .name .details{
        font-size: 1rem;
    }
    .pr-heading{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .pr-heading h2{
        font-size: 1.5rem;
    }
    .pr-heading h1{
        font-size: 3rem;
    }
    .pr-heading .details{
        font-size: 1rem;
    }
	.about-image img{
		height:400px;
    }
    #products{
		height:auto;
    }
    .a-container{
		flex-wrap: wrap;
	}
    .a-box{
		flex-grow: 1;
	}
    .a-b-img img{
		object-fit:contain;
    }
    
    

}



@media(max-width:970px){
	#main{
		height: 650px;
	}
	.name{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
    }
    .pr-heading{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
	.about-image{
        display:none;
    }
    .header-btn{
        margin-left: 20%;
        margin-top: 20px;
    }
    .pr-btn{
        margin-left: 20%;
        margin-top: 20px;
    }
	#about{
		justify-content: center;
        padding-top: 0px;
        text-align: center;
        
        margin-top: 0px;
	}
	.about-text{
        width: 90%;
	}
	.about-text h1{
		font-size:4rem;
    }
    .about-text p{
        width: 100%;
    }
    #products{
        margin-bottom: 100px;
    }
    #features1 h1 {
        color: #000000;
        font-family: "roboto";
        text-align: center;
        font-size:30px;
        margin-top: 50px;
    }
}
@media(max-width:600px){
	.name{
		width:60%;
    }
    .pr-heading{
		width:60%;
    }
}

@media(max-width:1100px){.menu-icon{
    display:block;}
        nav{
            justify-content: space-between;
            height: 65px;
            padding: 0px 30px;
        }
        .logo img{
            width:70px;
        }
        .header-btns{
            margin: 0;
        }
        .pr-btns{
            margin: 0;
        }
        .menu{
            display:none;
            position:absolute;
            top:65px;
            left:0px;
            background-color:#000000;
            border-bottom:4px solid #14d0ff;
            width:100%;
            padding:0px;
            margin:0px;
        }
        .menu li{
            width:100%;
        }
        nav .menu li a{
            width:100%;
            height:40px;
            justify-content: center;
            align-items: center;
            margin:0px;
            padding: 25px;
            border:1px solid rgba(38,38,38,0.03);
        }
        nav .menu-icon{
            cursor:pointer;
            float:right;
            padding:28px 20px;
            position:relative;
            user-select: none;
        }
        nav .menu-icon .nav-icon{
            background-color:#ffffff;
            display:block;
            height:2px;
            position:relative;
            transition: background 0.2s ease-out;
            width:24px;
        }
        nav .menu-icon .nav-icon:before,
        nav .menu-icon .nav-icon:after{
            background:rgb(255, 255, 255);
            content:'';
            display:block;
            height:100%;
            position:absolute;
            transition:all ease-out 0.2s;
            width:100%;
        }
        nav .menu-icon .nav-icon:before{
            top:6px;
        }
        nav .menu-icon .nav-icon:after{
            top:-6px;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon{
            background:transparent;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
            transform: rotate(-45deg);
            top:0;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
            transform: rotate(45deg);
            top:0;
        }
        nav .menu-btn{
            display:none;
        }
        nav .menu-btn:checked ~ .menu{
            display:block;
        }}
        @media(max-width:400px){
            .a-box{
                width: 100%;
                height:500px;
            }
        }


/* Chatgpt code below for alignment fix in mobile screens */ 

@media (max-width: 600px) {
    .a-box {
        width: 100%;
        /* Make the box take full width on smaller screens */
    }
}

.a-b-img {
    width: 100%;
    height: 60%;
    margin: auto;
}

.a-b-img img {
    padding: 10px;
    margin-top: 40px;
    margin-left: 50px;
    /* Adjusted margin for better centering on smaller screens */
    width: 60%;
    /* Adjusted width for better responsiveness */
    height: 60%;
    /* Adjusted height for better responsiveness */
}

.a-box:hover {
    border: 1px solid #14dcff;
    box-shadow: 10px 8px 18px rgba(22, 42, 158, 0.445);
    cursor: pointer;
}

.a-b-text {
    width: 100%;
    height: 95%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column; 
}

.a-b-text h2 {
    color: rgb(0, 0, 0);
}

.a-b-text p {
    margin: 0px;

    color: #000000;
    font-size: 0.9rem;
    font-family: 'Lato';
    font-weight: bolder;
    max-width: 80%;
    margin-top: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}



.a-b-text a {
    margin-top: 15px;
    
}



#signupL{
    background-size: cover;
    background-attachment: fixed;
    /* Set background attachment to fixed */
    background-image: url('./images/LoanBanner (2).jpg');
    position: relative;
    z-index: 0;
    height: 800px;
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
}

#signupL::before {
    position: absolute;
    content: "";
    background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#61b50808', endColorstr='#99000000', GradientType=1);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }


  .a-b-text p {
    cursor: pointer;
  }

  
  @media only screen and (max-width: 375px) {
    .a-b-text p {
        font-size: 0.8rem; /* Adjust font size for smaller screens */
    }
}  
  
 

.a-b-text p {
    margin: 0px;
    color: rgb(0, 0, 0);
    font-size: 0.9rem;
    font-family: 'Lato';
    font-weight: bolder;
    max-width: 100%; /* Adjusted max-width for mobile */
    margin-top: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    margin-bottom: 15px; /* Increased margin for better spacing */
    cursor: pointer; /* Add cursor pointer to indicate it's clickable */
    transition: color 0.3s; /* Add transition for smooth color change */
}



.a-b-text a {
    margin-top: 20px; /* Increased margin for better spacing */
    padding: 10px 20px; /* Add padding to increase the clickable area */
    color: #fff; /* Change link color to white */
    text-decoration: none; /* Remove default underline */
    display: inline-block; /* Display as inline block for better alignment */
    background-color: #14dcff; /* Add background color to make it stand out */
    border-radius: 5px; /* Add border-radius for rounded corners */
    transition: background-color 0.3s, color 0.3s; /* Add transition for smooth color change */
}

.a-b-text a:hover {
    background-color: #0bb7e3; /* Change background color on hover */
    color: #fff; /* Change text color on hover */
}

@media only screen and (max-width: 375px) {
    .a-b-text p {
        font-size: 0.8rem; /* Adjust font size for smaller screens */
    }
    .a-b-text a {
        margin-top: 5px; /* Adjusted margin for better spacing on mobile */
    }
}




.posts {
    margin-top: 10rem;
    margin-bottom: 10rem; /* Adjust this value as needed */
}

.posts__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns with equal width */
    gap: 4rem; /* spacing between grid items */
}

.post {
    background-color: #eefcff; /* just an example of additional styling */
    border: 1px solid #ddd; /* just an example of additional styling */
    padding: 1rem; /* just an example of additional styling */
    border-radius: 1.5rem;
    padding-bottom: 2rem;
    transition: all 0.3s ease-in-out;
        cursor: default;

}
.post:hover{
    box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.1);
}

.post__thumbnail{
    border-radius: 1.5rem;
    overflow: hidden;
    height: 16rem;
    margin: 0;
    padding: 0;
    display: flex;
  

}
.post__thumbnail img {
    width: 100%; /* Ensure the image fills the container */
    height: 100%; /* Maintain the container height */
    object-fit: cover; /* Ensure the image covers the entire container */
    display: block; /* Ensure no extra spaces around the image */
    margin: 0; /* Ensure no margins */
    padding: 0; /* Ensure no padding */
}
.post__content {
    margin-top: 1.5rem;
    padding: 1rem;
}

.post__content h3 {
    margin: 0.2rem 0 0.5rem;
    font-size:25px;
    color: black;
    font-style: normal;
    text-decoration: none;

}


.post__content p {
    margin: 0.5rem 0;
}



.post-detail{
    width: 70%;
    background: rgb(206, 206, 255);
    margin-inline: auto;
    padding: 2rem 0;
    margin-top: 150px;
    margin-bottom: 150px;
}


.post-detail__container{
    margin-bottom: 100px;
}

.post-detail__container h1{
        text-align: center;
}


.post-detail__thumbnail{
    margin: 1rem 0;
    height: fit-content;
    max-height: 30rem;
    margin-right: 170px;
    text-align: center;
}       

.post-detail p {
    margin-bottom: 0.7rem;
    max-width: 80%; /* Set a maximum width for paragraphs */
    margin-inline: auto; /* Center paragraphs within the container */
    text-align: left; /* Align text to the left for readability */
}

.post-detail__content {
    max-width: 800px; /* Set a maximum width for the content */
    margin-inline: auto; /* Center the content within the container */
    padding: 1rem; /* Optional padding for better spacing */
}





